import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_CommitmentCardView = _resolveComponent("CommitmentCardView")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, {
      loading: _ctx.pageLoading,
      disable: _ctx.pageLoading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              "no-gutters": "",
              class: "align-center"
            }, {
              default: _withCtx(() => [
                _cache[5] || (_cache[5] = _createTextVNode(" Commitment List ")),
                _createVNode(_component_v_divider, {
                  class: "mx-4",
                  inset: "",
                  vertical: ""
                }),
                _createVNode(_component_v_switch, {
                  dense: "",
                  label: "Active",
                  modelValue: _ctx.isActive,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isActive) = $event)),
                  color: "success",
                  "hide-details": ""
                }, null, 8, ["modelValue"]),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_switch, {
                  dense: "",
                  label: "Card View",
                  modelValue: _ctx.isCardView,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isCardView) = $event)),
                  color: "success",
                  "hide-details": ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, { class: "commitment-body" }, {
          default: _withCtx(() => [
            (!_ctx.isCardView)
              ? (_openBlock(), _createBlock(_component_v_data_table, {
                  key: 0,
                  items: _ctx.tempDesserts,
                  headers: _ctx.headers,
                  loading: _ctx.loading,
                  "custom-sort": _ctx.customSort
                }, {
                  "item.Name": _withCtx(({ item }) => [
                    _createElementVNode("strong", null, _toDisplayString(item.Name), 1)
                  ]),
                  "item.SeniorityLevel": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(item.SeniorityLevel), 1)
                  ]),
                  "item.LineManagerId": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(item.LineManagerName), 1)
                  ]),
                  "item.Status": _withCtx(({ item }) => [
                    _createVNode(_component_v_chip, {
                      class: "ma-2",
                      color: _ctx.checkCommitmentStatus(item.StatusV2)['color'],
                      variant: "flat"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.checkCommitmentStatus(item.StatusV2)["value"]), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"])
                  ]),
                  "item.StartDate": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.formatDate(item.StartDate)), 1)
                  ]),
                  "item.EndDate": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(_ctx.formatDate(item.EndDate)), 1)
                  ]),
                  "item.ProjectNames": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(item.ProjectNames ? _ctx.formatProjects(item.ProjectNames) : ""), 1)
                  ]),
                  "item.Type": _withCtx(({ item }) => [
                    _createTextVNode(_toDisplayString(item.HoursPerWeekRadio ? "Hours Per week" : "Casual") + " ", 1),
                    (item.HoursPerWeekRadio)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                          _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Min: ", -1)),
                          _createTextVNode(_toDisplayString(item.HoursPerWeekMin), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (item.HoursPerWeekRadio)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Max: ", -1)),
                          _createTextVNode(_toDisplayString(item.HoursPerWeekMax), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  "item.Action": _withCtx(({ item }) => [
                    _createVNode(_component_v_menu, {
                      "offset-y": "",
                      "close-on-click": ""
                    }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_icon, _normalizeProps(_guardReactiveProps(props)), {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode("mdi-dots-vertical")
                          ])),
                          _: 2
                        }, 1040)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item, {
                              link: "",
                              onClick: ($event: any) => (_ctx.showUpdateStatus(false, item.id)),
                              title: "Approve"
                            }, null, 8, ["onClick"]),
                            _createVNode(_component_v_list_item, {
                              link: "",
                              onClick: ($event: any) => (_ctx.showUpdateStatus(true, item.id)),
                              title: "Reject"
                            }, null, 8, ["onClick"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["items", "headers", "loading", "custom-sort"]))
              : (_openBlock(), _createBlock(_component_CommitmentCardView, {
                  key: 1,
                  items: _ctx.tempDesserts
                }, null, 8, ["items"]))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["loading", "disable"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showUpdateStatusPopup,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showUpdateStatusPopup) = $event)),
      persistent: "",
      "max-width": "400px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(!_ctx.rejectMode ? "Approve Commitment" : "Reject Commitment"), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _cache[9] || (_cache[9] = _createElementVNode("span", null, "Are you sure about this action ?", -1)),
                (_ctx.rejectMode)
                  ? (_openBlock(), _createBlock(_component_TextAreaField, {
                      key: 0,
                      modelValue: _ctx.rejectMessage,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rejectMessage) = $event)),
                      label: "Reason of Rejecting",
                      rules: [_ctx.validations.required]
                    }, null, 8, ["modelValue", "rules"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { md: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      loading: _ctx.loading,
                      class: "primary_btn mr-5",
                      dark: "",
                      onClick: _ctx.updateCommitmentStatus
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(!_ctx.rejectMode ? "Yes! Approve it" : 'Yes! Reject it'), 1)
                      ]),
                      _: 1
                    }, 8, ["loading", "onClick"]),
                    _createVNode(_component_v_btn, {
                      loading: _ctx.loading,
                      class: "secondary_btn",
                      dark: "",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showUpdateStatusPopup = false))
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("Cancel")
                      ])),
                      _: 1
                    }, 8, ["loading"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}